<template>
  <div class="d-flex justify-content-start">
    <!-- <b-avatar :src="params.data.image" class="flex-shrink-0 mr-2" size="40px" @click="$router.push(url)" /> -->
    <!-- <router-link
      :to="url"
      class="link-button text-inherit text-dark hover:text-primary"
      @click.stop.prevent
    >
      {{ params.value }}
    </router-link> -->
    <div>
      {{ params.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  props: {
    params: Object,
  },
  computed: {
    url() {
      return `/analytics/platform/${this.params.data.report_name}-${this.params.data.id}`
    },
  },

  mounted() {
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables.scss';

a:hover {
  color: $primary !important;
}
</style>
